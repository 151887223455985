import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Link } from 'react-router-dom';

const useVerificationWarning = (message: string, path: string) => {
    return (
      <>
      <hr/>
      <div style={{backgroundColor: "RGBA(252, 253, 185,1)", padding: "3px 0 3px 3px", borderRadius: 8, display: "flex", flexDirection: "row", alignItems: "center"}}>
        <ErrorOutlineIcon
          fontSize="large" 
          style={{paddingLeft: "3px"}}
        />
        <p style={{paddingLeft: 10, fontSize: 20}}>
            <Link to={path}>
            {message}
            </Link>
        </p>
      </div>
      </>
    )
  }

  export default useVerificationWarning