import {
  Button,
  TextField,
  MenuItem,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  Input,
  Chip,
  IconButton,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Dispatch, SetStateAction } from "react";
import { WebsiteAvailabilityGroup } from "../../types/ProductType";
import { MenuProps } from "../authComponents/Register";
import { filterParams } from "./Products";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { BranchType } from "../../types/BranchType";

const PREFIX = "ProductsSideBar";

const classes = {
  sideBarMain: `${PREFIX}-sideBarMain`,
  formControl: `${PREFIX}-formControl`,
  formControl2: `${PREFIX}-formControl2`,
};

const StyledPaper = styled("div")({
  [`&.${classes.sideBarMain}`]: {
    padding: "20px",
    margin: "5px",
    minWidth: 300,
    maxWidth: 500,
  },
  [`& .${classes.formControl}`]: {
    marginBottom: 10,
    minWidth: 220,
  },
  [`& .${classes.formControl2}`]: {
    marginTop: 12,
    marginBottom: 12,
    width: "100%",
  },
});

export type filterProps = {
  applyFilter: (filterParams: filterParams) => void;
  clearFilter: () => void;
  branches: BranchType[] | undefined;
  productList: WebsiteAvailabilityGroup[];
  setOpen: Dispatch<SetStateAction<boolean>>;
  entitys: string[];
  setEntitys: Dispatch<SetStateAction<string[]>>;
  genera: string[];
  species: string[];
  categories: string[];
  subcategories: string[];
  leafTypes: string[];
  stemTypes: string[];
  rootTypes: string[];
  quantityFilterTypes: string[];
  getSpecies: (genus: string) => Promise<void>;
  distinctSizes: string[];
  plantSize: string;
  plantGenus: string;
  plantSpecies: string;
  plantSubcategory: string;
  plantCategory: string;
  plantLeafType: string;
  plantStemType: string;
  plantRootball: string;
  searchString: string;
  filterCount: number;
  setPlantSize: Dispatch<SetStateAction<string>>;
  setPlantGenus: Dispatch<SetStateAction<string>>;
  setPlantSpecies: Dispatch<SetStateAction<string>>;
  setPlantSubcategory: Dispatch<SetStateAction<string>>;
  setPlantCategory: Dispatch<SetStateAction<string>>;
  setPlantLeafType: Dispatch<SetStateAction<string>>;
  setPlantStemType: Dispatch<SetStateAction<string>>;
  setPlantRootBall: Dispatch<SetStateAction<string>>;
  setFilterCount: Dispatch<SetStateAction<number>>;
};

const ProductsSideBar = (props: filterProps) => {
  const {
    plantSize,
    entitys,
    plantCategory,
    plantLeafType,
    plantRootball,
    plantStemType,
    plantGenus,
    plantSpecies,
    plantSubcategory,
    searchString,
    setPlantSize,
    setPlantGenus,
    setPlantSpecies,
    setPlantSubcategory,
    setPlantCategory,
    setPlantLeafType,
    setPlantStemType,
    setPlantRootBall,
    setEntitys,
  } = props;

  const distinctSizes = [
    ...new Set(props.productList.map((x) => x.size)),
  ].sort();
  const theme = useTheme();

  let filterData = {
    size: plantSize,
    entitys: entitys,
    category: plantCategory,
    leafType: plantLeafType,
    rootball: plantRootball,
    stemType: plantStemType,
    genus: plantGenus,
    species: plantSpecies,
    subCategory: plantSubcategory,
    searchString: searchString,
  } as filterParams;

  const useDropDown = (
    label: string,
    name: string,
    value: number[] | string,
    setState: any,
    formOptions: Array<string>
  ) => {
    const handleChange = (event: any) => {
      let state = event.target.value;
      let name = event.target.name;
      setState(state);

      if (name === "genus") {
        props.getSpecies(state);
      }

      props.applyFilter({ ...filterData, [name]: state });
    };

    const clearIndividualFilter = (filterData: filterParams) => {
      props.applyFilter({ ...filterData });
      setState("");
    };

    return (
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <TextField
          select
          className={classes.formControl2}
          variant="outlined"
          label={label}
          name={name}
          value={value}
          margin="dense"
          size="small"
          fullWidth
          onChange={handleChange}
        >
          {formOptions.map((x, index) => {
            return (
              <MenuItem key={index} value={x}>
                {x}
              </MenuItem>
            );
          })}
        </TextField>
        <Button
          size="small"
          onClick={() => clearIndividualFilter({ ...filterData, [name]: "" })}
        >
          X
        </Button>
      </div>
    );
  };

  const category = useDropDown(
    "Category",
    "category",
    plantCategory,
    setPlantCategory,
    props.categories
  );
  const subcategory = useDropDown(
    "SubCategory",
    "subCategory",
    plantSubcategory,
    setPlantSubcategory,
    props.subcategories
  );
  const genus = useDropDown(
    "Genus",
    "genus",
    plantGenus,
    setPlantGenus,
    props.genera
  );
  const species = useDropDown(
    "Species",
    "species",
    plantSpecies,
    setPlantSpecies,
    props.species
  );
  const stemType = useDropDown(
    "Stem Type",
    "stemType",
    plantStemType,
    setPlantStemType,
    props.stemTypes
  );
  const size = useDropDown(
    "Size",
    "size",
    plantSize,
    setPlantSize,
    distinctSizes
  );
  const leafType = useDropDown(
    "Leaf Type",
    "leafType",
    plantLeafType,
    setPlantLeafType,
    props.leafTypes
  );
  const rootball = useDropDown(
    "RootBall Type",
    "rootball",
    plantRootball,
    setPlantRootBall,
    props.rootTypes
  );

  const handleClear = (event: any) => {
    event.preventDefault();
    setPlantSize("");
    setPlantCategory("");
    setPlantLeafType("");
    setPlantRootBall("");
    setPlantSubcategory("");
    setPlantGenus("");
    setPlantSpecies("");
    setPlantStemType("");
    setEntitys([]);
    props.clearFilter();
    props.setOpen(false);
  };

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));

  const selectAvailability = () => {
    const handleChange = (e: any) => {
      let tempEntitys =
        e.target.value === "string"
          ? e.target.value.split(",")
          : e.target.value;
      props.setEntitys(tempEntitys);
      props.applyFilter({ ...filterData, entitys: tempEntitys });
    };

    return (
      <FormControl style={{ minWidth: 250, margin: 5 }} variant="outlined">
        <InputLabel>Filter By Branch Availability</InputLabel>
        <Select
          variant="outlined"
          name="company"
          label="Company"
          onChange={handleChange}
          value={props.entitys}
          multiple
          input={<Input />}
          renderValue={(selected: any) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 0.5,
              }}
            >
              {selected.map((value: string) => (
                <Chip key={value} label={value} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {props.branches?.map((x, index) => {
            return (
              <MenuItem
                style={{ whiteSpace: "unset", wordBreak: "break-all" }}
                key={index}
                value={x?.entityName}
              >
                {x?.entityName}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  return (
    <StyledPaper className={classes.sideBarMain}>
      <DrawerHeader>
        <IconButton onClick={() => props.setOpen(false)}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <DialogTitle
        style={{ cursor: "move" }}
        id="draggable-dialog-title"
        variant="h6"
      >
        Filter options
      </DialogTitle>
      <DialogContent>
        <form>
          {selectAvailability()}
          {size}
          {subcategory}
          {category}
          {genus}
          {species}
          {leafType}
          {stemType}
          {rootball}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button
              style={{ marginLeft: 10 }}
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleClear}
            >
              Clear
            </Button>
          </div>
        </form>
      </DialogContent>
    </StyledPaper>
  );
};

export default ProductsSideBar;
