import {TextField} from "@mui/material";
import SimpleAutoComplete from "../SimpleAutoComplete";
import React, {useContext} from "react";
import {CardWithNickname, PaymentMethod} from "../../../types/CardDataType";
import {StylesContext} from "../../../providers/StylesProvider";

const states = [
    { label: "Alabama", code: "AL" },
    { label: "Alaska", code: "AK" },
    { label: "Arizona", code: "AZ" },
    { label: "Arkansas", code: "AR" },
    { label: "California", code: "CA" },
    { label: "Colorado", code: "CO" },
    { label: "Connecticut", code: "CT" },
    { label: "Delaware", code: "DE" },
    { label: "Florida", code: "FL" },
    { label: "Georgia", code: "GA" },
    { label: "Hawaii", code: "HI" },
    { label: "Idaho", code: "ID" },
    { label: "Illinois", code: "IL" },
    { label: "Indiana", code: "IN" },
    { label: "Iowa", code: "IA" },
    { label: "Kansas", code: "KS" },
    { label: "Kentucky", code: "KY" },
    { label: "Louisiana", code: "LA" },
    { label: "Maine", code: "ME" },
    { label: "Maryland", code: "MD" },
    { label: "Massachusetts", code: "MA" },
    { label: "Michigan", code: "MI" },
    { label: "Minnesota", code: "MN" },
    { label: "Mississippi", code: "MS" },
    { label: "Missouri", code: "MO" },
    { label: "Montana", code: "MT" },
    { label: "Nebraska", code: "NE" },
    { label: "Nevada", code: "NV" },
    { label: "New Hampshire", code: "NH" },
    { label: "New Jersey", code: "NJ" },
    { label: "New Mexico", code: "NM" },
    { label: "New York", code: "NY" },
    { label: "North Carolina", code: "NC" },
    { label: "North Dakota", code: "ND" },
    { label: "Ohio", code: "OH" },
    { label: "Oklahoma", code: "OK" },
    { label: "Oregon", code: "OR" },
    { label: "Pennsylvania", code: "PA" },
    { label: "Rhode Island", code: "RI" },
    { label: "South Carolina", code: "SC" },
    { label: "South Dakota", code: "SD" },
    { label: "Tennessee", code: "TN" },
    { label: "Texas", code: "TX" },
    { label: "Utah", code: "UT" },
    { label: "Vermont", code: "VT" },
    { label: "Virginia", code: "VA" },
    { label: "Washington", code: "WA" },
    { label: "West Virginia", code: "WV" },
    { label: "Wisconsin", code: "WI" },
    { label: "Wyoming", code: "WY" }
];

export type AddressInputFieldsProps = {
    paymentMethod: CardWithNickname;
    setPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethod>>;
    asPaymentMethod: (card: CardWithNickname) => PaymentMethod;
}

const AddressInputFields: React.FC<AddressInputFieldsProps> = (props: AddressInputFieldsProps) => {
    const { asPaymentMethod } = props;
    const { isDesktop } = useContext(StylesContext);


    const renderDesktop = () => {
        return <>
            <TextField
                id={"streetAddress"}
                label="Street Address"
                variant="outlined"
                margin="dense"
                fullWidth
                value={props.paymentMethod.address.streetAddress}
                onChange={(event) => {
                    const newAddress = {...props.paymentMethod.address, streetAddress: event.target.value};
                    props.setPaymentMethod(asPaymentMethod({...props.paymentMethod, address: newAddress}));
                }}
            />
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <TextField
                    id={"city"}
                    label="City"
                    variant="outlined"
                    margin="dense" style={{width: "50%"}}
                    value={props.paymentMethod.address.city}
                    onChange={(event) => {
                        const newAddress = {...props.paymentMethod.address, city: event.target.value};
                        props.setPaymentMethod(asPaymentMethod({...props.paymentMethod, address: newAddress}));
                    }}
                />
                <SimpleAutoComplete
                    id={"state"}
                    label={"State"}
                    options={states.map((state) => state.label)}
                    value={states.find(state => state.code === props.paymentMethod.address.region)?.label || ""}
                    setValue={(value) => {
                        const stateCode = states.filter((state) => state.label === value)[0]?.code ?? ""

                        const newAddress = {...props.paymentMethod.address, region: stateCode};
                        props.setPaymentMethod(asPaymentMethod({...props.paymentMethod, address: newAddress}));
                    }}
                    style={{width: "30%", marginTop: 8}}
                />
                <TextField
                    id={"zipcode"}
                    label="Zipcode"
                    variant="outlined"
                    margin="dense"
                    style={{width: "15%"}}
                    value={props.paymentMethod.address.postalCode}
                    onChange={(event) => {
                        const value = event.target.value;
                        const isNumber = !isNaN(Number(value));

                        if (isNumber) {
                            const newAddress = {...props.paymentMethod.address, postalCode: event.target.value};
                            props.setPaymentMethod(asPaymentMethod({
                                ...props.paymentMethod,
                                address: newAddress
                            }));
                        }
                    }}
                />
            </div>
        </>
    }

    const renderMobile = () => {
        return <>
            <TextField
                id={"streetAddress"}
                label="Street Address"
                variant="outlined"
                margin="dense"
                fullWidth
                value={props.paymentMethod.address.streetAddress}
                onChange={(event) => {
                    const newAddress = {...props.paymentMethod.address, streetAddress: event.target.value};
                    props.setPaymentMethod(asPaymentMethod({...props.paymentMethod, address: newAddress}));
                }}
            />
            <TextField
                id={"city"}
                label="City"
                variant="outlined"
                margin="dense"
                style={{width: "100%"}}
                value={props.paymentMethod.address.city}
                onChange={(event) => {
                    const newAddress = {...props.paymentMethod.address, city: event.target.value};
                    props.setPaymentMethod(asPaymentMethod({...props.paymentMethod, address: newAddress}));
                }}
            />
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <SimpleAutoComplete
                    id={"state"}
                    label={"State"}
                    options={states.map((state) => state.label)}
                    value={states.find(state => state.code === props.paymentMethod.address.region)?.label || ""}
                    setValue={(value) => {
                        const stateCode = states.filter((state) => state.label === value)[0]?.code ?? ""

                        const newAddress = {...props.paymentMethod.address, region: stateCode};
                        props.setPaymentMethod(asPaymentMethod({...props.paymentMethod, address: newAddress}));
                    }}
                    style={{width: "59%", marginTop: 8}}
                />
                <TextField
                    id={"zipcode"}
                    label="Zipcode"
                    variant="outlined"
                    margin="dense"
                    style={{width: "39%"}}
                    value={props.paymentMethod.address.postalCode}
                    onChange={(event) => {
                        const value = event.target.value;
                        const isNumber = !isNaN(Number(value));

                        if (isNumber) {
                            const newAddress = {...props.paymentMethod.address, postalCode: event.target.value};
                            props.setPaymentMethod(asPaymentMethod({
                                ...props.paymentMethod,
                                address: newAddress
                            }));
                        }
                    }}
                />
            </div>
        </>
    }

    return <>{isDesktop ? renderDesktop() : renderMobile()}</>
}

export default AddressInputFields;
