import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import {
  Container,
  TextField,
  useMediaQuery,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ShipmentType } from "../../types/ShipmentType";
import Axios from "axios";
import TreeSourceLoad from "../Loaders/TreeSourceLoad";
import { useContext } from "react";
import { AuthContext } from "../../providers/AuthProvider";
import useVerificationWarning from "../../hooks/useVerificationWarning";
import { Link, useHistory } from "react-router-dom";
import { CUSTOMER_NAME, formatter } from "../../constants/Constants";
import { datadogLogs } from "@datadog/browser-logs";
import WarningIcon from "@mui/icons-material/Warning";
import DoneIcon from "@mui/icons-material/Done";
import { StylesContext } from "../../providers/StylesProvider";
import AccountBalanceCard from "../guiComponents/payments/AccountBalanceCard";
import SalesRepInfo from "../guiComponents/SalesRepInfo";

const PREFIX = "OrderManagement";

const classes = {
  orderManagement: `${PREFIX}-orderManagement`,
  shipments: `${PREFIX}-shipments`,
  orderLineItems: `${PREFIX}-orderLineItems`,
  orderLineItemsPhone: `${PREFIX}-orderLineItemsPhone`,
  searchBar: `${PREFIX}-searchBar`,
  totalBar: `${PREFIX}-totalBar`,
  totalBarPhone: `${PREFIX}-totalBarPhone`,
  headerText: `${PREFIX}-headerText`,
  headerContent: `${PREFIX}-headerContent`,
  headerLine: `${PREFIX}-headerLine`,
  dataGridPhone: `${PREFIX}-dataGridPhone`,
};

const Root = styled("div")({
  [`& .${classes.orderManagement}`]: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
  },
  [`& .${classes.shipments}`]: {
    flexGrow: 1,
    margin: 10,
    marginBottom: 30,
    minHeight: "700px",
    maxHeight: "700px",
  },
  [`& .${classes.orderLineItems}`]: {
    flexGrow: 2,
    margin: 10,
    minHeight: "600px",
    maxHeight: "600px",
  },
  [`& .${classes.orderLineItemsPhone}`]: {
    flexGrow: 2,
    minHeight: "500px",
    maxHeight: "500px",
  },
  [`& .${classes.searchBar}`]: {
    margin: 10,
    width: "100%",
  },
  [`& .${classes.totalBar}`]: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  [`& .${classes.totalBarPhone}`]: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    margin: 10,
  },
  [`& .${classes.headerText}`]: {
    fontWeight: "bold",
    fontSize: "12pt",
  },
  [`& .${classes.headerContent}`]: {
    fontSize: "12pt",
    marginRight: "10px",
  },
  [`& .${classes.headerLine}`]: {
    display: "inline-flex",
  },
  [`& .${classes.dataGridPhone}`]: {
    marginRight: 10,
    marginLeft: 10,
  },
});

const OrderManagement = () => {
  const matches = useMediaQuery("(min-width:716px");

  const auth = useContext(AuthContext);
  const customer = auth.user?.customer;
  const { navBarHeight, isDesktop } = useContext(StylesContext);

  const mountedRef = useRef(true);

  const history = useHistory();

  //state for Orders
  const [searchString, setSearchString] = useState("");
  const [shipments, setShipments] = useState<ShipmentType[]>([]);
  const [filteredShipments, setFiltedShipments] = useState<ShipmentType[]>([]);
  const [ordersLoading, setOrdersLoading] = useState(true);
  const [ordersError, setOrdersError] = useState(false);
  const [orderErrorMessage, setOrderErrorMessage] = useState("");

  const verification = useVerificationWarning(
    "Your account is still currently being verified. Please check back later to see your orders.",
    ""
  );

  useEffect(() => {
    getShipments();
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getShipments = async () => {
    try {
      const config = {
        headers: {
          authorization: localStorage.getItem("token"),
          Accept: "application/json",
          "Content-Type": "application/json",
          "Cache-Control": "no-store",
        },
      };

      let customerName = localStorage.getItem("customerName");
      if (customerName === null && auth.userCustomers) {
        customerName = auth.userCustomers[0]?.fullName as string | null;
      }

      let encodedCustomerName = encodeURIComponent(
        localStorage.getItem(CUSTOMER_NAME) as string
      );

      let res = await Axios.get(
        `/api/shipments?selectedCustomerName=${encodedCustomerName}`,
        config
      );
      let formattedShipments = res.data.map((x: any) => {
        let newDate = x.landDate ? new Date(x.landDate) : null;
        let fullId = x.orderID + "-" + x.shipmentID;
        return { ...x, landDate: newDate, fullId: fullId };
      });

      if (!mountedRef.current) {
        return null;
      }

      datadogLogs.logger.info("User loaded the order management page.");

      setShipments(formattedShipments);
      setFiltedShipments(formattedShipments);
      setOrdersLoading(false);
    } catch (error: any) {
      if (!mountedRef) return null;

      datadogLogs.logger.info(
        "We ran into an error while loading the order list",
        { errorData: error.response.data }
      );

      setOrdersLoading(false);
      setOrdersError(true);
      setOrderErrorMessage(
        error.response.data && typeof error.response.data === "string"
          ? error.response.data
          : "Error Loading Data"
      );
      if (
        error.response.data ===
        "The token was expected to have 3 parts, but got 1."
      ) {
        history.go(0);
      }
      return error;
    }
  };

  const toolTipStatusMessage = (status: string) => {
    switch (status) {
      case "COMMITTED":
        return "On sales order.";
      case "PULLING":
        return "Currently being loaded on a delivery truck.";
      case "SHIPPED":
        return "The order has left our dock.";
      case "OPEN":
        return "This is a quote/estimate.";
      case "INVOICED":
        return "This order is complete.";
      default:
        return "";
    }
  };

  const shipmentColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "Order #",
      width: 125,
      renderCell: (params) => {
        return (
          <Tooltip title="Order # and Shipment #">
            <Link
              to={`/order-management/order/${params.row.orderID}/shipment/${params.row.shipmentID}/view/orderview`}
            >
              {params.row.orderID + "-" + params.row.shipmentID}
            </Link>
          </Tooltip>
        );
      },
    },
    {
      field: "jobName",
      headerName: "Job Name",
      width: 250,
    },
    {
      field: "grandTotal",
      headerName: "Total Cost",
      width: 150,
      renderCell: (params) => {
        return <div>{formatter.format(params.row.grandTotal)}</div>;
      },
    },
    {
      field: "shipmentBalance",
      headerName: "Balance Due",
      width: 150,
      renderCell: (params) => {
        let shipmentDoc = params.row;
        let hasBalance = shipmentDoc.shipmentBalance > 0;
        let dueDate = shipmentDoc.dueDate
          ? Date.parse(shipmentDoc.dueDate)
          : null;
        let today = new Date().getTime();
        let pastDue = hasBalance && dueDate !== null && dueDate < today;

        if (shipmentDoc.shipmentBalance && pastDue) {
          return (
            <div
              style={{
                border: "red",
                borderStyle: "solid",
                padding: "6px",
                borderRadius: "7px",
                borderWidth: "thin",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <WarningIcon color="error" />
              <Typography variant="body2" style={{ color: "red" }}>
                {formatter.format(params.row.shipmentBalance)}
              </Typography>
            </div>
          );
        } else if (shipmentDoc.shipmentBalance) {
          return (
            <div
              style={{
                border: "orange",
                borderStyle: "solid",
                padding: "6px",
                borderRadius: "7px",
                borderWidth: "thin",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <WarningIcon color="warning" />
              <Typography variant="body2" style={{ color: "black" }}>
                {formatter.format(params.row.shipmentBalance)}
              </Typography>
            </div>
          );
        } else if ("INVOICED" === shipmentDoc.status && shipmentDoc.dueDate) {
          return (
            <div
              style={{
                border: "green",
                borderStyle: "solid",
                padding: "6px",
                borderRadius: "7px",
                borderWidth: "thin",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <DoneIcon color="success" />
              <Typography variant="body2" style={{ color: "green" }}>
                Paid
              </Typography>
            </div>
          );
        } else if ("INVOICED" === shipmentDoc.status && !shipmentDoc.dueDate) {
          return <div>Syncing...</div>;
        } else {
          return <div>N/A</div>;
        }
      },
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      width: 125,
      type: "date",
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        return (
          <Tooltip title={toolTipStatusMessage(params.row.status)}>
            <span>{params.row.status}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "landDate",
      headerName: "Land Date",
      width: 125,
      type: "date",
    },
  ];

  const handleSearchSubmit = (value: string) => {
    setSearchString(value);

    value = value.toLowerCase();
    const foo = shipments.filter(
      (x) =>
        x?.fullId?.includes(value) ||
        x?.jobName?.toLowerCase().includes(value) ||
        x?.status?.toLowerCase().includes(value) ||
        x?.grandTotal?.toString().includes(value) ||
        x?.shipmentBalance?.toString().includes(value)
    );
    setFiltedShipments(foo);
  };

  const renderNoShipmentsError = () => {
    return (
      <>
        <h1>Sorry, We're not able to find any orders for you yet!</h1>
        <p>
          If you were expecting to see orders listed here, we apologize. On
          January 1st, 2023 we moved to a new centralized system. For the time
          being, this change prevents us from displaying quotes and orders prior
          to January 1st, 2023.
        </p>

        <p>
          If you are in need of specific orders before January 1st, 2023 please
          contact your sales rep and they will be able to manually help you get
          the information you're looking for.
        </p>

        <p>
          Thank you for your patience while we work on providing you the best
          experience possible.
        </p>
      </>
    );
  };

  const renderShipments = () => {
    if (ordersLoading) {
      return <TreeSourceLoad message="Loading Orders" />;
    }
    if (ordersError) {
      return <h1>{orderErrorMessage}</h1>;
    }
    if (filteredShipments.length < 1) {
      return renderNoShipmentsError();
    }

    return (
      <div>
        <DataGrid
          style={{ height: "500px" }}
          rows={filteredShipments}
          columns={shipmentColumns}
          components={{
            Toolbar: GridToolbar,
          }}
          onRowClick={(x) => {
            history.push(
              `/order-management/order/${x.row.orderID}/shipment/${x.row.shipmentID}/view/orderview`
            );
          }}
        />
      </div>
    );
  };

  const renderNotVerified = () => {
    return (
      <Container>
        <div style={{ paddingTop: navBarHeight }}>
          <h1>Order Management</h1>
          {auth.user?.customer?.fullName || auth.userCustomers ? (
            <hr />
          ) : (
            verification
          )}
        </div>
      </Container>
    );
  };

  if (!auth.user?.customer?.fullName && !auth.userCustomers) {
    return renderNotVerified();
  }

  const renderOrderList = () => {
    return <>
      <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <h1>Order Management</h1>
        {/*<Button style={{marginRight: 10}} variant="contained" color="primary">*/}
        {/*  Start Shopping Now!*/}
        {/*</Button>*/}
      </div>
      <div className={classes.searchBar}>
        <form
            onSubmit={(e) => e.preventDefault()}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "100%"
            }}
        >
          <TextField
              label="Search for an order"
              onChange={(event: any) => handleSearchSubmit(event.target.value)}
              value={searchString}
              id="standard-basic"
              variant="outlined"
              style={{width: "100%", marginRight: 20}}
          />
        </form>
      </div>
      <div className={classes.orderManagement}>
        <div
            className={
              matches ? classes.shipments : classes.orderLineItemsPhone
            }
        >
          {renderShipments()}
        </div>
      </div>
    </>
  }

  const renderDesktop = () => {
    return <Container>
      <Root style={{paddingTop: navBarHeight}}>
        <div style={{display: "flex", flexDirection: "row", alignItems: "stretch"}}>
          <div style={{flex: 1}}>
            <AccountBalanceCard showPaymentLink={true}/>
          </div>
          <div style={{flex: customer?.salesRep?.name ? 1 : 0}}>
            <SalesRepInfo/>
          </div>
        </div>

        {renderOrderList()}

      </Root>
    </Container>
  }

  const renderMobile = () => {
      return <>
        <Root style={{paddingTop: navBarHeight}}>
          <div style={{display: "flex", flexDirection: "column", alignItems: "stretch"}}>
            <div style={{flex: 1}}>
              <AccountBalanceCard showPaymentLink={true}/>
            </div>
            <div style={{flex: customer?.salesRep?.name ? 1 : 0}}>
              <SalesRepInfo/>
            </div>
          </div>

          <Container>
            {renderOrderList()}
          </Container>


        </Root>
      </>
  }

  return <>
    {isDesktop ? renderDesktop() : renderMobile()}
  </>
};

export default OrderManagement;
