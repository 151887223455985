import {Autocomplete, TextField} from "@mui/material";
import React from "react";

export type SimpleAutoCompleteProps = {
    id: string;
    label: string;
    options: string[];
    value: string;
    setValue: (value: string) => void;
    error?: boolean;
    style: React.CSSProperties;
}

const SimpleAutoComplete: React.FC<SimpleAutoCompleteProps> = (props: SimpleAutoCompleteProps) => {
    const [inputValue, setInputValue] = React.useState('');


    return (
        <Autocomplete
            id={props.id}
            style={props.style}
            options={props.options}
            onKeyDown={(event) => {
                switch (event.key) {
                    case "Tab": {
                        if (inputValue.length > 0 && props.options.includes(inputValue)) {
                            // props.setPaymentMethod({...props.paymentMethod, expMonth: expMonthInput})
                            props.setValue(inputValue);
                        } else {
                            // props.setPaymentMethod({...props.paymentMethod, expMonth: ""})
                            props.setValue("");
                        }
                        break;
                    }
                    default:
                }
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            value={props.value}
            onChange={(event, value) => {
                const target = value ?? "";

                if (target.length > 0 && props.options.includes(target)) {
                    // props.setPaymentMethod({...props.paymentMethod, expMonth: target})
                    props.setValue(target);
                } else {
                    // props.setPaymentMethod({...props.paymentMethod, expMonth: ""})
                    props.setValue("");
                }
            }}
            renderInput={(params) => <TextField {...params} error={props.error ?? false} label={props.label} />}
        />
    );
};

export default SimpleAutoComplete;