import { useState, useEffect, useContext, useCallback } from "react";
import { styled } from "@mui/material/styles";
import { WebsiteAvailabilityGroup } from "../../types/ProductType";
import {
  Alert,
  Autocomplete,
  Badge,
  Button,
  Container,
  Drawer,
  Pagination,
  Paper,
  PaperProps,
  Snackbar,
  TextField,
} from "@mui/material";
import ProductsSideBar from "./ProductsSideBar";
import { AuthContext } from "../../providers/AuthProvider";
import {
  DataGridPro,
  DataGridProProps,
  GridEnrichedColDef,
  gridPageCountSelector,
  gridPageSelector,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import { Dialog, useMediaQuery } from "@mui/material";
import {
  CUSTOMER_NAME,
  ENTITY_NAME,
  NEW_ORDER,
} from "../../constants/Constants";
import SearchBar from "./SearchBar";
import axios from "axios";
import { useHistory, useParams } from "react-router";
import ImageIcon from "@mui/icons-material/Image";
import { ProductCard } from "./ProductCard";
import PersistentDrawerRight from "./PersistentDrawerRight";
import { useQuery } from "../../hooks/useQuery";
import { addHeadersAndCallBackend } from "../../endpoints/AddHeadersAndCallBackend";
import { showAddFeature } from "../../featureFlags/showAddFeature";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Draggable from "react-draggable";
import { datadogLogs } from "@datadog/browser-logs";
import { StylesContext } from "../../providers/StylesProvider";
import { Error } from "../global/Error";
import { BranchContext } from "../../providers/BranchProvider";
import React from "react";
import { Link } from "../guiComponents/Link";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import DownloadIcon from "@mui/icons-material/Download";
import { DownloadDialog } from "../guiComponents/DownloadDialog";
import { OrderContext } from "../../providers/OrderProvider";
import ItemAvailabilityGroup from "../guiComponents/ItemAvailabilityGroup";
import ProductListItem from "../guiComponents/ProductListItem";
import {GlobalContext} from "../../providers/GlobalContext";
import {getDisplayPrice} from "../../Utilities/ProductUtils";

const PREFIX = "Products";

const classes = {
  root: `${PREFIX}-root`,
  table: `${PREFIX}-table`,
  productPageParent: `${PREFIX}-productPageParent`,
  productPageParentPhone: `${PREFIX}-productPageParentPhone`,
  productList: `${PREFIX}-productList`,
  productListPhone: `${PREFIX}-productListPhone`,
  formControl: `${PREFIX}-formControl`,
};

const drawerWidth = 500;
const adjustedDrawerWidth = drawerWidth - 320;

const Root = styled("div")({
  [`& .${classes.root}`]: {
    "MuiDialog-paperWidthLg": {
      width: "300px",
    },
  },
  [`& .${classes.table}`]: {
    minWidth: 0,
  },
  [`& .${classes.productPageParent}`]: {
    display: "flex",
    flexWrap: "wrap-reverse",
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginBottom: 100,
    height: "80vh",
  },
  [`& .${classes.productPageParentPhone}`]: {
    display: "flex",
    flexWrap: "wrap-reverse",
    flexDirection: "column",
    justifyContent: "space-evenly",
    marginBottom: 100,
    height: "80vh",
  },
  [`& .${classes.productList}`]: {
    flexGrow: 8,
    marginTop: "5px",
  },
  [`& .${classes.productListPhone}`]: {
    height: "500px",
    flexGrow: 8,
    marginTop: "5px",
    marginBottom: "5px",
  },
  [`& .${classes.formControl}`]: {
    margin: 20,
    minWidth: 120,
  },
});

const PaperComponent = (props: PaperProps) => {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
};

export interface filterParams {
  minPrice: number | undefined;
  maxPrice: number | undefined;
  minAvailability: number | undefined;
  size: string | undefined;
  entitys: string[] | undefined;
  genus: string | undefined;
  species: string | undefined;
  category: string | undefined;
  subCategory: string | undefined;
  leafType: string | undefined;
  rootball: string | undefined;
  stemType: string | undefined;
  qtyFilterType: string | undefined;
  qtyFilterComparison: string | undefined;
  qtyFilterValue: number | undefined;
  searchString: string | undefined;
  maxResults: number | undefined;
}

type dialogContent = {
  botanicalName: string;
  commonName: string;
  itemCode: string;
  size: string;
  quantity: number;
  price: number;
  imageLink: string;
  quantityOnPO: number;
  vendor: string;
  shipmentType: string;
};

export type urlParams = {
  roottype: string;
  qtytypefilter: string;
  catalogformatting: string;
  location: string;
};

export type OrderForAdd = {
  label: string;
  orderID: number;
  orderName: string;
};

export const getCompanyName = (location: String) => {
  return "guest@guest.com";
};

const Products = () => {
  const { roottype, qtytypefilter, catalogformatting } = useParams<urlParams>();

  const history = useHistory();
  let query = useQuery();

  const { branches } = useContext(BranchContext);
  const {
    setSelectedOrderForAdd,
    selectedOrderForAdd,
    getUnfinishedOrders,
    orders: editableOrders,
  } = useContext(OrderContext);
  const { lastProductSearch, setLastProductSearch } = useContext(GlobalContext);

  const orderId = parseInt(useQuery().get("orderid") as string);

  useEffect(() => {
    if (setSelectedOrderForAdd) {
      setSelectedOrderForAdd({
        ...NEW_ORDER,
        orderID: orderId || -1,
      });
    }

    getGenus();
    getFilters();

    if (getUnfinishedOrders && auth.authenticated) {
      getUnfinishedOrders();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const ordersHaveLoaded =
      editableOrders?.length && editableOrders.length > 0;

    if (ordersHaveLoaded) {
      getQueryParams(editableOrders as OrderForAdd[]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableOrders]);

  const { navBarHeight, isDesktop } = useContext(StylesContext);

  const tablet = useMediaQuery("(min-width:1280px");

  const auth = useContext(AuthContext);
  const showPricing = auth.isConfiguredUser ? auth.isConfiguredUser() : false;


  useEffect(() => {
    setLoading(true);
    let entitys = [
      auth.user?.userCustomerAssociation?.defaultLocalBranch
        ? auth.user?.userCustomerAssociation?.defaultLocalBranch
        : (auth.loggedOutLocation as string),
    ];
    setEntitys(entitys);
    let initalFilterParams = {
      rootball: roottype,
      qtyFilterType: qtytypefilter,
      maxResults: 1000,
      entitys: entitys,
      searchString: lastProductSearch,
    } as filterParams;
    getFilterCount(initalFilterParams);
    getProducts(initalFilterParams, auth.loggedOutLocation);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.loggedOutLocation as String]);

  const getQueryParams = (orders: OrderForAdd[]) => {
    let qpOrderId = query.get("orderid");
    let qpShipmentId = query.get("shipmentid");

    if (
      qpOrderId !== undefined &&
      qpOrderId !== null &&
      qpShipmentId !== undefined &&
      qpShipmentId !== null
    ) {
      let order = orders.filter((x) => x.orderID.toString() === qpOrderId)[0];

      if (setSelectedOrderForAdd) {
        setSelectedOrderForAdd(order);
      }

      setOpenDrawer(true);
      handleChangeOrder(order);
    }
  };

  const [products, setProducts] = useState<WebsiteAvailabilityGroup[]>([]);

  //State for productSideBar Filter
  const distinctSizes = [...new Set(products.map((x) => x.size))].sort();

  const [plantSize, setPlantSize] = useState("");
  const [plantGenus, setPlantGenus] = useState("");
  const [plantSpecies, setPlantSpecies] = useState("");
  const [plantSubcategory, setPlantSubcategory] = useState("");
  const [plantCategory, setPlantCategory] = useState("");
  const [plantLeafType, setPlantLeafType] = useState("");
  const [plantStemType, setPlantStemType] = useState("");
  const [plantRootball, setPlantRootBall] = useState(roottype);
  const [entitys, setEntitys] = useState<string[]>([
    auth.loggedOutLocation as string,
  ]);
  const [filterCount, setFilterCount] = useState(0);
  // End of State for ProductSideBarFilters

  const [genera, setGenera] = useState(["All"]);
  const [species, setSpecies] = useState(["All"]);
  const [quantityFilters] = useState([
    "X-Dock Availability",
    "Availability",
    "Qty On Hand",
    "Qty On PO",
    "Qty On SO",
  ]);
  const [subcategories, setSubcategories] = useState(["All"]);
  const [categories, setCategories] = useState(["All"]);
  const [leafTypes, setLeafTypes] = useState(["All"]);
  const [stemTypes, setStemTypes] = useState(["All"]);
  const [rootTypes, setRootTypes] = useState(["All"]);

  const [openItemDetail, setOpenItemDetail] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [dialogContent, setDialogContent] = useState<dialogContent>({
    botanicalName: "",
    commonName: "",
    itemCode: "",
    size: "",
    quantity: 0,
    price: 0,
    imageLink: "",
    quantityOnPO: 0,
    vendor: "",
    shipmentType: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "category", sort: "desc" },
    { field: "leafType", sort: "asc" },
    { field: "rootball", sort: "asc" },
  ]);

  const [errorToastOpen, setErrorToastOpen] = useState(false);
  const [errorToastMessage, setErrorToastMessage] =
    useState<String>("TestME!!");
  const [successToastOpen, setSuccessToastOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openDownloadAvails, setOpenDownloadAvails] = useState(false);

  const getGenus = async () => {
    try {
      let res = await axios.get("/api/products/genera");
      setGenera(res.data);
    } catch (error) {}
  };

  const getSpecies = async (genus: string) => {
    try {
      let res = await axios.get(`/api/products/genus/${genus}/species`);
      setSpecies(res.data);
    } catch (error) {}
  };

  const getFilters = async () => {
    try {
      let res = await axios.get("/api/products/filters");
      setSubcategories(res.data[0]);
      setCategories(res.data[1]);
      setLeafTypes(res.data[2]);
      setStemTypes(res.data[3]);
      setRootTypes(res.data[4]);
    } catch (error) {}
  };

  const handleErrorToastClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorToastOpen(false);
  };

  const handleSuccessToastClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessToastOpen(false);
  };

  const isExpired = () => {
    if (auth.isTokenExpired) {
      return auth.isTokenExpired();
    }
    return true;
  };

  const columns: GridEnrichedColDef[] = [
    {
      field: "commonName",
      headerName: "Common Name",
      width: isDesktop ? 230 : 150,
      renderCell: (params) => {
        const onClick = (e: any) => {
          setOpenItemDetail(true);
          setDialogContent({
            botanicalName: params.row.botanicalName,
            commonName: params.row.commonName,
            itemCode: params.row.itemCode,
            size: params.row.size,
            quantity: params.row.quantity,
            price: params.row.yardPrice,
            imageLink: params.row.imageLink,
            quantityOnPO: params.row.quantityOnPO,
            vendor: "",
            shipmentType: "",
          });
        };
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
            onClick={onClick}
          >
            <ImageIcon style={{}} />
            <Link style={{ paddingLeft: 4 }} content={params.row.commonName} />
          </div>
        );
      },
    },
    {
      field: "botanicalName",
      headerName: "Botanical Name",
      width: 230,
      hide: isDesktop ? false : true,
      renderCell: (params) => {
        const onClick = (e: any) => {
          setOpenItemDetail(true);
          setDialogContent({
            botanicalName: params.row.botanicalName,
            commonName: params.row.commonName,
            itemCode: params.row.itemCode,
            size: params.row.size,
            quantity: params.row.quantity,
            price: params.row.yardPrice,
            imageLink: params.row.imageLink,
            quantityOnPO: params.row.quantityOnPO,
            vendor: "",
            shipmentType: "",
          });
        };
        return (
          <span onClick={onClick}>
            <Link content={params.row.botanicalName} />
          </span>
        );
      },
    },
    {
      field: "category",
      headerName: "Category",
      width: 100,
      hide: isDesktop ? false : true,
    },
    {
      field: "leafType",
      headerName: "Leaf Type",
      width: 110,
      hide: isDesktop ? false : true,
    },
    {
      field: "rootball",
      headerName: "Root Type",
      width: 110,
      hide: isDesktop ? false : true,
    },
    {
      field: "size",
      headerName: "Size",
      width: 80,
      hide: isDesktop ? false : true,
    },
    {
      field: "quantity",
      headerName: "Available",
      width: 80,
      hide: catalogformatting === "enable" ? true : false,
    },
    {
      field: "priceFloor",
      headerName: "Price",
      width: 80,
      hide: !showPricing,
      renderCell(params) {
        return <span>{getDisplayPrice(params.row.priceFloor)}</span>;
      },
    },
    { field: "itemCode", headerName: "ItemCode", width: 200, hide: true },
    { field: "searchScore", headerName: "Score", width: 60, hide: true },
  ];

  const DetailPanelContent = ({
    row: rowProp,
  }: {
    row: WebsiteAvailabilityGroup;
  }) => {
    return (
      <>
        <ItemAvailabilityGroup
          itemAvailGroup={rowProp}
          catalogformatting={catalogformatting}
          setErrorToastMessage={setErrorToastMessage}
          setErrorToastOpen={setErrorToastOpen}
          setSuccessToastOpen={setSuccessToastOpen}
        />
      </>
    );
  };

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridProProps["getDetailPanelContent"]>
  >(
    ({ row }) => {
      return <DetailPanelContent row={row} />;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  function ordersAreEqual(option: any, value: any) {
    if (value === undefined) {
      return false;
    }
    if (
      option.orderID === value.orderID &&
      option.shipmentID === value.shipmentID
    ) {
      return true;
    } else {
      return false;
    }
  }

  const getProducts = async (
    filterParams: filterParams,
    locationName: String = auth.loggedOutLocation as String
  ) => {
    try {
      let minPrice = filterParams.minPrice || 0;
      let maxPrice = filterParams.maxPrice || 1000;
      let minAvailability = filterParams.minAvailability || 0;
      let size = filterParams.size || "";
      let entitys = filterParams.entitys || [];
      let genus = filterParams.genus || "";
      let species = filterParams.species || "";
      let category = filterParams.category || "";
      let subCategory = filterParams.subCategory || "";
      let leafType = filterParams.leafType || "";
      let rootball = filterParams.rootball || "";
      let stemType = filterParams.stemType || "";
      let qtyFilterType = filterParams.qtyFilterType || "Availability";
      let qtyFilterComparison = filterParams.qtyFilterComparison || ">";
      let qtyFilterValue = filterParams.qtyFilterValue || 0;
      let searchString = filterParams.searchString || "";
      let maxResults = isDesktop ? filterParams.maxResults || 1000 : 20;

      let encodedSearchString = encodeURIComponent(
        searchString as string | number | boolean
      );

      let entitysUrl = new URLSearchParams();
      entitys.forEach((entity) => {
        entitysUrl.append("entitys", entity);
      });

      let qpEmail =
        localStorage.getItem("token") == null || isExpired()
          ? `email=${getCompanyName(locationName)}`
          : "email=guest@guest.com";
      let qpMinPrice = `&minPrice=${minPrice}`;
      let qpMaxPrice = maxPrice ? `&maxPrice=${maxPrice}` : `&maxPrice=1000`;
      let qpMinAvailability = `&minAvailability=${minAvailability}`;
      let qpSize =
        size.length > 0
          ? size.includes("#")
            ? `&size=${size.replace("#", "%23")}`
            : `&size=${size}`
          : "";
      let qpEntitys = entitys.length > 0 ? "&" + entitysUrl.toString() : "";
      let qpGenus = genus?.length > 0 ? `&genus=${genus}` : "";
      let qpSpecies = genus?.length > 0 ? `&species=${species}` : "";
      let qpCategory =
        category?.length > 0 && category !== "All"
          ? `&category=${category.charAt(0)}`
          : "";
      let qpSubcategory =
        subCategory.length > 0 ? `&subcategory=${subCategory}` : "";
      let qpLeafType =
        leafType.length > 0 && leafType !== "All"
          ? `&leafType=${leafType.charAt(0)}`
          : "";
      let qpRootBall =
        rootball.length > 0 && rootball !== "All"
          ? `&rootball=${rootball.charAt(0)}`
          : "";
      let qpStemType =
        stemType.length > 0 && stemType !== "All"
          ? `&stemType=${stemType.charAt(0)}`
          : "";
      let qpQtyFilterType =
        qtyFilterType.length > 0
          ? `&qtyFilterType=${qtyFilterType}`
          : "&qtyFilterType=Available";
      let qpQtyFilterComparison =
        qtyFilterComparison.length > 0
          ? `&qtyFilterComparison=${qtyFilterComparison}`
          : "&qtyFilterComparison=>";
      let qpQtyFilterValue = `&qtyFilterValue=${qtyFilterValue}`;
      let qpSearchString =
        searchString.length > 0 ? `&searchString=${encodedSearchString}` : "";
      let customerName =
        localStorage.getItem("token") == null ||
        isExpired() ||
        auth.user?.customer?.entityName === undefined
          ? `&${CUSTOMER_NAME}=unverified`
          : `&${CUSTOMER_NAME}=${encodeURIComponent(
              localStorage.getItem(CUSTOMER_NAME) as string
            )}`;
      let entityName =
        localStorage.getItem("token") == null ||
        isExpired() ||
        auth.user?.customer?.entityName === undefined
          ? `&${ENTITY_NAME}=${locationName}`
          : `&${ENTITY_NAME}=${auth.user?.userCustomerAssociation?.defaultLocalBranch}`;
      let qpMaxResults = `&maxResults=${maxResults}`;

      let url = `/api/products?${qpEmail}${qpSearchString}${qpMinPrice}${qpMaxPrice}${qpMinAvailability}${qpSize}${qpGenus}${qpSpecies}${qpCategory}${qpSubcategory}${qpLeafType}${qpRootBall}${qpStemType}${qpQtyFilterType}${qpQtyFilterComparison}${qpQtyFilterValue}${customerName}${entityName}${qpEntitys}${qpMaxResults}`;

      datadogLogs.logger.info(
        "Making call for " + entityName + " : " + encodedSearchString
      );
      let res = await addHeadersAndCallBackend(url);

      if (searchString) {
        setSortModel([{ field: "searchScore", sort: "desc" }]);
      }

      setProducts(res?.data);
      setLoading(false);
    } catch (err) {
      setError(true);
      setLoading(false);
    }
  };

  const applyFilter = (filterParams: filterParams) => {
    getFilterCount(filterParams);
    setLoading(true);
    getProducts(filterParams, auth.loggedOutLocation);
  };

  const clearFilter = () => {
    setLoading(true);
    getFilterCount({} as filterParams);
    getProducts({ maxResults: 1000 } as filterParams, auth.loggedOutLocation);
  };

  const renderDataGrid = (products: WebsiteAvailabilityGroup[]) => {
    return (
      <div className={isDesktop ? classes.productList : classes.productListPhone}>
        <DataGridPro
          sortModel={sortModel}
          pagination
          getDetailPanelHeight={() => "auto"}
          getDetailPanelContent={getDetailPanelContent}
          rowsPerPageOptions={[100]}
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          style={{ height: "80vh", border: !isDesktop ? 0 : "" }}
          rows={products}
          columns={columns}
          density={isDesktop ? "standard" : "compact"}
          loading={loading}
          components={{
            Toolbar: CustomToolbar,
            Pagination: CustomPagination,
            DetailPanelExpandIcon: UnfoldMoreIcon,
            DetailPanelCollapseIcon: UnfoldLessIcon,
          }}
        />
      </div>
    );
  };

  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <Button onClick={() => setOpenDownloadAvails(true)}>
          <DownloadIcon />
          Download Availability
        </Button>
      </GridToolbarContainer>
    );
  }

  const selectionInformation = () => {
    //I'm making an unauthorized decision that on mobile, it's important to use our space for product info
    //rather than nice to have alerts.
    if(!isDesktop) {
        return <></>
    }

    return <>
      <Alert severity="info">
        Did you know? We offer better pricing if you can receive a shipment
        directly. Call your sales rep today for details!
      </Alert>
      <br />
      <Alert severity="success">
        We offer a huge selection of special order plants! Let your rep know
        what you're looking for, and we'll do our best to find it for you!
      </Alert>
      <br />
    </>
  }

  const searchBar = () => {
    if(lastProductSearch === undefined || setLastProductSearch === undefined) {
        return <></>
    }

    return (
      <SearchBar
        submitSearchString={lastProductSearch}
        setSearchString={setLastProductSearch}
        applyFilter={applyFilter}
        clearFilter={clearFilter}
        plantSize={plantSize}
        plantGenus={plantGenus}
        plantSpecies={plantSpecies}
        plantSubcategory={plantSubcategory}
        plantCategory={plantCategory}
        plantLeafType={plantLeafType}
        plantStemType={plantStemType}
        plantRootball={plantRootball}
        searchString={lastProductSearch}
        entitys={entitys}
      />
    );
  };

  const viewOrderButton = () => {
    return (
      <Button
        style={{ marginLeft: 10 }}
        variant="contained"
        color="primary"
        onClick={() => handleDrawerOpen()}
      >
        View Order
      </Button>
    );
  };

  const viewOrderButtonPhone = () => {
    return (
      <Button
        style={{ marginLeft: 10 }}
        variant="contained"
        color="primary"
        onClick={() => handleDrawerOpen()}
      >
        <ViewHeadlineIcon />
      </Button>
    );
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleChangeOrder = (value: OrderForAdd) => {
    if (setSelectedOrderForAdd) {
      setSelectedOrderForAdd(value == null ? NEW_ORDER : value);
    }

    history.push({
      pathname: `/products`,
      search: `?orderid=${value == null ? -1 : value.orderID}&shipmentid=1`,
    });
  };

  const getFilterCount = (filterParams: filterParams) => {
    let number = 0;
    if (filterParams.size) {
      number = number + 1;
    }
    if (filterParams.genus) {
      number = number + 1;
    }
    if (filterParams.species) {
      number = number + 1;
    }
    if (filterParams.subCategory) {
      number = number + 1;
    }
    if (filterParams.category) {
      number = number + 1;
    }
    if (filterParams.leafType) {
      number = number + 1;
    }
    if (filterParams.stemType) {
      number = number + 1;
    }
    if (filterParams.rootball) {
      number = number + 1;
    }
    if (filterParams.entitys && filterParams.entitys.length > 0) {
      number = number + 1;
    }
    setFilterCount(number);
  };

  const renderFilterButton = () => {
    return (
      <Badge badgeContent={filterCount} color="error">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenFilter(!openFilter)}
        >
          {" "}
          {isDesktop ? "Filter" : <FilterAltIcon />}{" "}
        </Button>
      </Badge>
    );
  };

  const itemDetailDialog = () => {
    return (
      <>
        <Dialog
          maxWidth="lg"
          open={openItemDetail}
          onClose={() => {
            setOpenItemDetail(false);
          }}
          PaperComponent={PaperComponent}
        >
          <ProductCard
            imageLink={dialogContent.imageLink}
            header={dialogContent.botanicalName}
            subheader={dialogContent.commonName}
            size={dialogContent.size}
            quantity={dialogContent.quantity}
            quantityOnPO={dialogContent.quantityOnPO}
          />
          <Button
            onClick={() =>
              window.open(`/products/${dialogContent.itemCode}`, "_blank")
            }
          >
            View Product
          </Button>
        </Dialog>
      </>
    );
  };

  const closeFilter = () => {
    setOpenFilter(false);
  };

  const renderProducts = () => {
    if (isDesktop) {
      return renderDataGrid(products);

    } else {
      return products.map((product) => (
          <ProductListItem key={product.id} product={product} showPricing={showPricing}/>
      ));
    }
  };

  if (error) {
    return (
      <>
        <Error
          errorHeader="Availability List Loading Error"
          errorMessage="We're have some trouble getting the availability list to load. Please wait a few minutes before you try again. "
        />
      </>
    );
  }
  return (
    <Root
      style={{
        paddingTop: isDesktop ? navBarHeight : 50,
        width: `calc(100% - ${
          openDrawer && isDesktop ? adjustedDrawerWidth : 0
        }px)`,
      }}
    >
      <Container>
        <h2>Availability List</h2>
        {selectionInformation()}
        {catalogformatting === "enable" ? "" : searchBar()} {/*I think catalogFormatting was a feature Landon used to publish BnB availability on the website for spring presales */}
        <div
          style={{ marginBottom: 20, display: "flex", flexDirection: "row" }}
        >
          {renderFilterButton()}
          <Autocomplete
            id="open-order-combo-box"
            options={editableOrders as OrderForAdd[]}
            hidden={!showAddFeature()}
            style={{ marginLeft: 10, width: 350 }}
            renderInput={(params) => (
              <TextField {...params} label="Add To Open Order" />
            )}
            onChange={(event, value) => handleChangeOrder(value as OrderForAdd)}
            value={selectedOrderForAdd}
            isOptionEqualToValue={ordersAreEqual}
          />
          {showAddFeature()
            ? !isDesktop
              ? viewOrderButtonPhone()
              : viewOrderButton()
            : ""}
        </div>

        {renderProducts()}

        {/*Below here are control elements. Filter dialogs, a feature to show the selected order to the right of the screen, snackbars etc. */}
        <div className={!isDesktop ? "" : classes.productPageParent}>
          <Drawer
            open={openFilter}
            onClose={closeFilter}
            variant="temporary"
            anchor="left"
            aria-labelledby="draggable-dialog-title"
          >
            {/*Turns out, this is the sidebar that displays the filter options for our data query*/}
            <ProductsSideBar
              distinctSizes={distinctSizes}
              plantSize={plantSize}
              plantGenus={plantGenus}
              plantSpecies={plantSpecies}
              plantSubcategory={plantSubcategory}
              plantCategory={plantCategory}
              plantLeafType={plantLeafType}
              plantStemType={plantStemType}
              plantRootball={plantRootball}
              searchString={lastProductSearch ? lastProductSearch : ""}
              entitys={entitys}
              filterCount={filterCount}
              setEntitys={setEntitys}
              setPlantSize={setPlantSize}
              setPlantGenus={setPlantGenus}
              setPlantSpecies={setPlantSpecies}
              setPlantSubcategory={setPlantSubcategory}
              setPlantCategory={setPlantCategory}
              setPlantLeafType={setPlantLeafType}
              setPlantStemType={setPlantStemType}
              setPlantRootBall={setPlantRootBall}
              setFilterCount={setFilterCount}
              applyFilter={applyFilter}
              clearFilter={clearFilter}
              branches={branches}
              productList={products}
              setOpen={setOpenFilter}
              subcategories={subcategories}
              categories={categories}
              leafTypes={leafTypes}
              stemTypes={stemTypes}
              rootTypes={rootTypes}
              quantityFilterTypes={quantityFilters}
              genera={genera}
              species={species}
              getSpecies={getSpecies}
            />
          </Drawer>
        </div>
        {itemDetailDialog()}
        <DownloadDialog
          open={openDownloadAvails}
          setOpen={setOpenDownloadAvails}
        />
        <div>
          <Snackbar
            open={errorToastOpen}
            autoHideDuration={2000}
            onClose={handleErrorToastClose}
          >
            <Alert
              onClose={handleErrorToastClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              {errorToastMessage}
            </Alert>
          </Snackbar>
        </div>
        <div>
          <Snackbar
            open={successToastOpen}
            autoHideDuration={2000}
            onClose={handleSuccessToastClose}
          >
            <Alert
              onClose={handleErrorToastClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Item added successfully!
            </Alert>
          </Snackbar>
        </div>
        {/*I think this shows the currently selected order on the right hand side of the screen. */}
        <PersistentDrawerRight
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          shipmentid={"1"} /*Temp fix until we write the new order screen*/
          orderid={selectedOrderForAdd?.orderID.toString() as string}
          successToastOpen={successToastOpen}
          drawerWidth={drawerWidth}
          phone={isDesktop ?? true}
          tablet={tablet}
        />
      </Container>

    </Root>
  );
};

export default Products;
