import { useState, useContext, useEffect } from "react"
import { AuthContext } from "../../providers/AuthProvider"
import { RouteComponentProps, RouteProps } from "react-router"
import { Button, FormControl, InputLabel, MenuItem, Select, TextField, Input, Theme, Container} from "@mui/material"
import TreeSourceLoad from "../Loaders/TreeSourceLoad"
import { createStyles, makeStyles} from '@mui/styles';
import { USER_ROLES } from "../../constants/Constants"
import { BranchContext } from '../../providers/BranchProvider';
import { StylesContext } from "../../providers/StylesProvider"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      m: 1
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    input: {
      margin: 10
    }
  }),
);

type Props = {
  history: RouteComponentProps
} & RouteProps

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Register: React.FC<Props> = (props) => {

  const {branches} = useContext(BranchContext)
  const {navBarHeight} = useContext(StylesContext)
  const classes = useStyles();

  const [email, setEmail] = useState("")
  const [firstName, setFirstName] = useState<string | undefined>("")
  const [lastName, setLastName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [title, setTitle] = useState("")
  const [entity, setEntity] = useState<String>("")
  const [customerCompanyName, setCustomerCompanyName] = useState("")

  //Error Messages
  const [emailError, setEmailError] = useState<String>("")

  const [firstNameError, setFirstNameError] = useState<String>("")
  const [lastNameError, setLastNameError] = useState<String>("")
  const [phoneNumberError, setPhoneNumberError] = useState<String>("")
  const [titleError, setTitleError] = useState<String>("")
  const [customerCompanyError, setCustomerCompanyError] = useState<String>("")

  const auth = useContext(AuthContext)

  const guid = auth.userGuid ? auth.userGuid : null
  const customerName = auth.user?.customer?.fullName ? auth.user?.customer.fullName : null
  const verified = auth.user ? 1 : 0
  const guidExpirationUndefined = auth.user?.guidExpiration ? auth.user.guidExpiration : null
  const guidExpiration = auth.user?.verified === 1 ? null : guidExpirationUndefined

  const { handleRegister, errorMessage, setErrorMessage } = useContext(AuthContext)

  useEffect(() => {
    if (auth.user && !auth.user.verified) {
      setEmail(auth.user.email)
      setFirstName(auth.user.firstName)
      setLastName(auth.user.lastName)
      setPhoneNumber(auth.user.phoneNumber)
      setTitle(auth.user.title)
      setEntity(auth.user.entityName)
    }
  }, [auth.user])


  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (!validForm()) {
      if (setErrorMessage) { setErrorMessage("It looks like there are some fields you haven't filled out correctly, please try again.") }
    } else
      if (handleRegister) { handleRegister({ email, password: null, firstName, lastName, phoneNumber, title, entityName: "", customerName, customerPreferredCompanyName: customerCompanyName, guid, guidExpiration, verified }, props.history, entity) }
  }

  const validForm = () => {
    if (emailError.length === 0 && firstNameError.length === 0 && lastNameError.length === 0 && phoneNumberError.length === 0 && customerCompanyError.length === 0 && titleError.length === 0) {
      return true
    }
    return false
  }


  const validateInput = (inputName: String, textToValidate: String) => {

    const phonenum = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/

    switch (inputName) {
      case "First Name":
        setFirstNameError(textToValidate.length > 50 ? "This field cannot be over 50 characters" : "");
        break;

      case "Last Name":
        setLastNameError(textToValidate.length > 50 ? "This field cannot be over 50 characters" : "");
        break;

      case "Email":
        setEmailError(!textToValidate.includes("@") ? "Please input a valid email address" : "");
        break;

      case "Phone Number":
        setPhoneNumberError(!textToValidate.match(phonenum) ? "Please enter a valid phone number. i.e 555-555-5555" : "");
        break;

      case 'Customer Company Name':
        setCustomerCompanyError(textToValidate.length > 50 ? "This field cannot be over 50 characters" : "")
        break;

      case "Title":
        setTitleError(textToValidate.length > 15 ? "This field cannot be over 15 characters" : "");
        break;

      default:
        break;
    }
  }



  const renderErrorMessage = () => {

    if (errorMessage != null) {
      return (
        <>
          <p>{errorMessage}</p>
          <p><a href = "/user/forgottenpassword"> Having trouble? Click here to reset your password</a></p>
        </>
      )
    }
  }

  if (auth.loadingRegister) {
    return (
      <TreeSourceLoad message={`Registering ${firstName} ${lastName}`} />
    )
  }

  return (
    <Container>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", paddingTop: navBarHeight }}>
        <h1 style={{ textAlign: "center" }}>Register</h1>
        {renderErrorMessage()}
        <form onSubmit={handleSubmit} >
          <div style={{ display: "flex", flexDirection: "row", minWidth: "400px", flexFlow: "wrap" }}>
            <div style={{ display: "flex", flexDirection: "column", minWidth: "400px" }}>
              <TextField
                className={classes.input}
                variant="standard"
                required
                disabled={auth.user ? true : false}
                autoFocus
                name="First Name"
                label="First Name"
                error={firstNameError ? true : false}
                helperText={firstNameError ? firstNameError : ""}
                onChange={(e) => {
                  setFirstName(e.target.value)
                  validateInput(e.target.name, e.target.value)
                }
                }
                value={firstName}
              />
              <TextField
                className={classes.input}
                required
                variant="standard"
                name="Last Name"
                disabled={auth.user ? true : false}
                label="Last Name"
                error={lastNameError ? true : false}
                helperText={lastNameError ? lastNameError : ""}
                onChange={(e) => {
                  setLastName(e.target.value)
                  validateInput(e.target.name, e.target.value)
                }
                }
                value={lastName}
              />
              <TextField
                className={classes.input}
                required
                name="Email"
                disabled={auth.user ? true : false}
                variant="standard"
                label="Email"
                error={emailError ? true : false}
                helperText={emailError ? emailError : ""}
                onChange={(e) => {
                  setEmail(e.target.value)
                  validateInput(e.target.name, e.target.value)
                }
                }
                value={email}
              />
              <TextField
                className={classes.input}
                required
                variant="standard"
                disabled={auth.user ? true : false}
                name="Phone Number"
                label="Phone Number"
                error={phoneNumberError ? true : false}
                helperText={phoneNumberError ? phoneNumberError : ""}
                onChange={(e) => {
                  setPhoneNumber(e.target.value)
                  validateInput(e.target.name, e.target.value)
                }
                }
                value={phoneNumber}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column", minWidth: "400px" }}>

              <TextField
                className={classes.input}
                required
                variant="standard"
                disabled={auth.user ? true : false}
                name="Customer Company Name"
                label="Company Name"
                error={customerCompanyError ? true : false}
                helperText={customerCompanyError ? customerCompanyError : ""}
                onChange={(e) => {
                  setCustomerCompanyName(e.target.value)
                  validateInput(e.target.name, e.target.value)
                }}
                value={customerCompanyName}
              />
              <FormControl variant="standard" required className={classes.formControl}>
                <InputLabel>Role</InputLabel>
                <Select
                  name="role"
                  label="Role"
                  onChange={(e: any) => setTitle(e.target.value)}
                  value={title}
                  disabled={auth.user ? true : false}
                >
                  {USER_ROLES.map(x => {
                    return(
                      <MenuItem key={x+"key"} value = {x}>{x}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>              
              <FormControl variant="standard" required className={classes.formControl}>
                <InputLabel>Preferred Branch</InputLabel>
                <Select
                    variant="outlined"
                      name="company"
                      label="Company"
                      onChange={(e)=>setEntity(e.target.value)}
                      value={entity}
                      input={<Input/>}
                      MenuProps={MenuProps}
                    >
    
                    {
                        branches?.map((x, index)=>{
                        
                        return(
                            <MenuItem style={{whiteSpace: "unset", wordBreak: "break-all"}} key={index} value = {x?.entityName} >
                                {x?.entityName}
                            </MenuItem>
                        )
                        })
                    }
                    </Select>
              </FormControl>

              <Button
                className={classes.input}
                type="submit"
                variant="contained"
                color="primary"
              > Register
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Container>
  )
}

export default Register