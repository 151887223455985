import React from 'react';
import { Card, CardMedia, CardContent, Typography } from '@mui/material';
import {WebsiteAvailabilityGroup} from "../../types/ProductType";
import {scale} from "@cloudinary/url-gen/actions/resize";
import {Cloudinary} from "@cloudinary/url-gen";
import {useHistory} from "react-router";
import {getDisplayPrice} from "../../Utilities/ProductUtils";

type ProductListItemProps = {
    key: string;
    product: WebsiteAvailabilityGroup;
    showPricing: boolean;
}

const ProductListItem = ( props: ProductListItemProps ) => {
    //utils
    const history = useHistory();
    const cloudinary = new Cloudinary({
        cloud: {
            cloudName: 'treesource-systems'
        }
    });

    //functions
    const optionalPrice = () => {
        if(props.showPricing){
            return(
                <><Typography variant="body2">Price: {getDisplayPrice(props.product.priceFloor)}</Typography></>
            )
        }
    }

    const getSizedItemUrl = (link: string) => {
        let publicId = "v1661178206/BandB Trees/default_photo"

        if(link !== undefined && link !== ""){
            publicId = props.product.imageLink.split("upload/")[1].split(".")[0].replaceAll('%20'," ")
        }

        let myImage = cloudinary.image(publicId)
        myImage.resize(scale().width(300).height(400))

        return myImage.toURL()
    }

    return (
    <Card style={{ display: 'flex', marginBottom: '10px' }}
          onClick={() =>
              history.push({
                  pathname: `/products/${props.product.itemCode}`,
              })
          }
    >
      <CardMedia
        component="img"
        image={getSizedItemUrl(props.product.imageLink)}
        alt={props.product.commonName}
        style={{ width: '33%', objectFit: 'cover' }}
      />
      <CardContent style={{ width: '67%' }}>
        <Typography variant="h6">{props.product.commonName}</Typography>
        <Typography variant="subtitle1">{props.product.botanicalName}</Typography>
        <Typography variant="body2">Size: {props.product.size}</Typography>
        {props.product.websiteItemAvailabilities.map((webAvail) => (
            <Typography variant="body2">{webAvail.companyName}: {webAvail.quantity}</Typography>
        ))}
          {optionalPrice()}


      </CardContent>
    </Card>
  );
};

export default ProductListItem;