import { OrderForAdd } from "../components/LoggedOut/Products";

export const passwordString: string = "Password";
export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
export const CUSTOMER_NAME = "customerName";
export const ENTITY_NAME = "entityName";
export const SHOW_ADD_TO_SHIP = "addToShipments";
export const SHOW_ABOUT_US_PAGE = "aboutUsPage";
export const USER_ROLES = [
  "Sales",
  "Owner",
  "Accounts Payable",
  "Accounts Receivable",
  "Landscaper",
  "Office",
  "General Manager",
  "Purchaser",
  "Other",
];

export const DEFAULT_BRANCH = "Rupert Farm";

export const NEW_ORDER: OrderForAdd = {
  label: "New Order",
  orderID: -1,
  orderName: "New Order",
};
export const NO_PRICE_DISPLAY: string = "Call for price.";
