import React, { useContext, useState } from 'react';
import {CardContent, CardHeader} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { AuthContext } from '../../providers/AuthProvider';
import { styled } from "@mui/material/styles";
import {KeyValue} from "../KeyValue";
import EditAccountInfo from "./EditAccountInfo";
import {PaddedCard} from "../LoggedOut/PaddedCard";

const PREFIX = "AccountInfoCard"; //I'm not sure how I feel about this pattern, but I'm just extracting the component so I'll go with it.

const classes = {
    editButton: `${PREFIX}-editButton`,
    textGroup: `${PREFIX}-textGroup`,
};

const StyledDiv = styled("div")(({ theme }) => ({
    [`& .${classes.editButton}`]: {
        cursor: "pointer",
        marginLeft: "5px",
        marginRight: "20px",
    },
    [`& .${classes.textGroup}`]: {
        display: "flex",
        flexDirection: "row",
        flexFlow: "nowrap",
        alignItems: "center",
        marginBottom: "10px",
    },
}));

const AccountInfoCard: React.FC = () => {
    const [open, setOpen] = useState(false);
    const [editValue, setEditValue] = useState<string>("");
    const [editType, setEditType] = useState<string>("");
    const [editName, setEditName] = useState<string>("");

    const Auth = useContext(AuthContext);
    const firstName = Auth.user?.firstName;
    const lastName = Auth.user?.lastName;
    const email = Auth.user?.email;
    const phoneNumber = Auth.user?.phoneNumber;
    const title = Auth.user?.title;
    const companyName = Auth.user?.customer?.companyName;

    const handleOpen = (value: string, typeValue: string, nameValue: string) => {
        setOpen(true);
        setEditValue(value);
        setEditType(typeValue);
        setEditName(nameValue);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const companyNameSection = () => {
        return (
            <div className={classes.textGroup}>
                {KeyValue("Company Name: ", companyName as string, <EditIcon
                    className={classes.editButton}
                    onClick={() =>
                        handleOpen(companyName as string, "text", "customerName")
                    }
                />)}
            </div>
        );
    }

    return (
        <StyledDiv  style={{ height: "100%" }}>
            <PaddedCard >
                <CardContent>
                    <CardHeader title="Account Information"/>
                    {/*do we care enough about the reachout feature to include it?*/}
                    {companyName ? companyNameSection() : ""}
                    <div className={classes.textGroup}>
                        {KeyValue("First Name: ", firstName as string, <EditIcon
                            className={classes.editButton}
                            onClick={() =>
                                handleOpen(firstName as string, "text", "firstName")
                            }
                        />)}
                    </div>
                    <div className={classes.textGroup}>
                        {KeyValue("Last Name: ", lastName as string, <EditIcon
                            className={classes.editButton}
                            onClick={() =>
                                handleOpen(lastName as string, "text", "lastName")
                            }
                        />)}
                    </div>
                    <div className={classes.textGroup}>
                        {KeyValue("Email: ", email as string)}
                    </div>
                    <div className={classes.textGroup}>
                        {KeyValue("Phone Number: ", phoneNumber as string, <EditIcon
                            className={classes.editButton}
                            onClick={() =>
                                handleOpen(phoneNumber as string, "text", "phoneNumber")
                            }
                        />)}
                    </div>
                    <div className={classes.textGroup}>
                        {KeyValue("Title: ", title as string, <EditIcon
                            className={classes.editButton}
                            onClick={() =>
                                handleOpen(title as string, "dropDown", "title")
                            }
                        />)}
                    </div>
                </CardContent>
            </PaddedCard>

            <EditAccountInfo open={open} editName={editName} editType={editType} editValue={editValue} setEditValue={setEditValue} handleClose={handleClose}/>
        </StyledDiv>
    );
};

export default AccountInfoCard;