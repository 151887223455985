import { TextField, Button } from "@mui/material"
import { styled } from '@mui/material/styles';
import { useContext, useState } from "react"
import { RouteComponentProps, RouteProps } from "react-router";
import { AuthContext } from "../../providers/AuthProvider";

const PREFIX = 'SetUserPassword';

const classes = {
    formControl: `${PREFIX}-formControl`,
    selectEmpty: `${PREFIX}-selectEmpty`,
    input: `${PREFIX}-input`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.formControl}`]: {
        margin: theme.spacing(1),
        minWidth: 120,
    },

    [`& .${classes.selectEmpty}`]: {
        marginTop: theme.spacing(2),
    },

    [`& .${classes.input}`]: {
        margin: 10
    }
}));

type Props = {
    history: RouteComponentProps
} & RouteProps

const SetUserPassword: React.FC<Props> = (props) => {



    const { handleVerifyUserWithPassword, setErrorMessage, userGuid, user } = useContext(AuthContext)

    let guid = userGuid ? userGuid : null

    const [password, setPassword] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [passwordError, setPasswordError] = useState<String>("")
    const [passwordConfirmError, setPasswordConfirmError] = useState<String>("")

    const validateInput = (inputName: String, textToValidate: String) => {

        const passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/

        switch (inputName) {
            case "Password":
                setPasswordError(!textToValidate.match(passw) ? "Password must be between 6 to 20 characters and contain at least one number, one uppercase and one lowercase letter" : "");
                break;

            case "Password Confirmation":
                setPasswordConfirmError(textToValidate !== password ? "Password doesn't match" : "");
                break;
            default:
                break;
        }
    }

    const validForm = () => {
        if (passwordError.length === 0 && passwordConfirmError.length === 0) {
            return true
        }
        return false
    }


    const handleSubmit = (e: any) => {
        e.preventDefault()
        if (!validForm()) {
            if (setErrorMessage) { setErrorMessage("It looks like there are some fields you haven't filled out correctly, please try again.") }
        } else
            if (handleVerifyUserWithPassword) {
                handleVerifyUserWithPassword(guid, password, props.history)
            }
    }


    const passwordComponents = () => {
        return (
            <>
                <h1>Setting Password for {`${user?.firstName} ${user?.lastName}`}</h1>
                <TextField
                    className={classes.input}
                    required
                    variant="outlined"
                    name="Password"
                    label="Password"
                    type="password"
                    hidden
                    error={passwordError ? true : false}
                    helperText={passwordError ? passwordError : ""}
                    onChange={(e) => {
                        setPassword(e.target.value)
                        validateInput(e.target.name, e.target.value)
                    }
                    }
                    value={password}
                />
                <TextField
                    className={classes.input}
                    required
                    variant="outlined"
                    name="Password Confirmation"
                    label="Confirm Password"
                    type="password"
                    error={passwordConfirmError ? true : false}
                    helperText={passwordConfirmError ? passwordConfirmError : ""}
                    onChange={(e) => {
                        setPasswordConfirm(e.target.value)
                        validateInput(e.target.name, e.target.value)
                    }
                    }
                    value={passwordConfirm}
                />
            </>
        )
    }

    return (
        <Root style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: 200 }}>
            <form onSubmit={handleSubmit} className={classes.formControl} style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                {passwordComponents()}
                <Button

                    className={classes.input}
                    type="submit"
                    variant="contained"
                    color="primary"
                > Confirm Password
                </Button>
            </form>
        </Root>
    );
}

export default SetUserPassword