import {PaddedCard} from "../../LoggedOut/PaddedCard";
import {Alert, Button, CardContent, CardHeader, TextField, Typography} from "@mui/material";
import React, {useContext, useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {BoldTypography} from "../../KeyValue";
import treesourceLogo from "../../Loaders/graphic-test3-01.svg";
import {StylesContext} from "../../../providers/StylesProvider";

type PaymentSummaryCardProps = {
    paymentDescription: string;
    totalAmount: number;
    submitPayment: (ReCAPTCHA: string, notes: string) => void;
    errorMessage: string;
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
    loading: boolean;
}
const PaymentSummaryCard = (props: PaymentSummaryCardProps) => {
    //local state
    const [notes, setNotes] = useState<string>("");
    const [recaptchaToken, setRecaptchaToken] = useState<string>("");

    //context
    const {isDesktop} = useContext(StylesContext);

    const loadingLayout = () => {
        return (
            <PaddedCard>
                <CardHeader title="Finalize"/>
                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%"}}>
                    <img style={{width: 200}} src={treesourceLogo} alt="treesource loading spinner"/>
                    <p>Thanks! We're processing your payment...</p>
                </div>
            </PaddedCard>
        );
    }

    const renderDesktop = () => {
        return <PaddedCard>
            <CardHeader title="Finalize"/>

            {props.errorMessage && (
                <CardContent>
                    <Alert onClose={() => props.setErrorMessage("")} severity="error">
                        {props.errorMessage}
                    </Alert>
                </CardContent>
            )}

            <CardContent>
                <div style={{display: "flex", alignItems: "flex-start", justifyContent: "space-between", width: "100%"}}>
                    <div style={{width: "45%", marginLeft: 10}}>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 20}}>
                            <div style={{width: "20%"}}>
                                <BoldTypography>Summary:</BoldTypography>
                            </div>
                            <div style={{width: "80%"}}>
                                <Typography>{props.paymentDescription}</Typography>
                            </div>
                        </div>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 20}}>
                            <div style={{width: "20%"}}>
                                <BoldTypography>Notes:</BoldTypography>
                            </div>
                            <div style={{width: "80%"}}>
                                <TextField
                                    inputProps={{
                                        maxLength: 1000,
                                    }}
                                    onChange={(event) => setNotes(event?.target.value)}
                                    value={notes}
                                    error={notes.length > 1000}
                                    helperText={notes.length > 1000 ? "Note length must be less than 1000 characters." : ""}
                                    fullWidth
                                    label="Optional notes for our AR team"
                                />
                            </div>
                        </div>
                    </div>

                    <div style={{width: "35%"}}>
                        Payment services are provided by Intuit Payments Inc.
                        <div style={{marginTop: 10}}>
                            <ReCAPTCHA
                                sitekey="6Le1uZYeAAAAAETWoZ4IbNCLNZVbfcUPHUqYbWd1"
                                onChange={(value: any) => {
                                    setRecaptchaToken(value)
                                }}
                            />
                        </div>

                    </div>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "10%"}}>
                        <Button
                            id="submitCountButton"
                            type="submit"
                            variant="contained"
                            color="success"
                            disabled={props.paymentDescription === "No payment indicated."}
                            style={{marginTop: 20}}
                            onClick={() => props.submitPayment(recaptchaToken, notes)}
                        >
                            Submit Payment!
                        </Button>
                    </div>
                </div>

            </CardContent>
        </PaddedCard>;
    }

    const renderMobile = () => {
        return (
            <PaddedCard>
                <CardHeader title="Finalize"/>

                {props.errorMessage && (
                    <CardContent>
                        <Alert onClose={() => props.setErrorMessage("")} severity="error">
                            {props.errorMessage}
                        </Alert>
                    </CardContent>
                )}

                <CardContent>
                    <div style={{display: "flex", flexDirection: "column", alignItems: "stretch", width: "100%"}}>
                        <div style={{width: "100%", marginBottom: 20}}>
                            <div style={{display: "flex", flexDirection: "column", alignItems: "stretch", marginBottom: 20}}>
                                <BoldTypography>Summary:</BoldTypography>
                                <Typography>{props.paymentDescription}</Typography>
                            </div>
                            <div style={{display: "flex", flexDirection: "column", alignItems: "stretch"}}>
                                <BoldTypography>Notes:</BoldTypography>
                                <TextField
                                    inputProps={{
                                        maxLength: 1000,
                                    }}
                                    onChange={(event) => setNotes(event?.target.value)}
                                    value={notes}
                                    error={notes.length > 1000}
                                    helperText={notes.length > 1000 ? "Note length must be less than 1000 characters." : ""}
                                    fullWidth
                                    label="Optional notes for our AR team"
                                />
                            </div>
                        </div>

                        <div style={{width: "100%", marginBottom: 20}}>
                            Payment services are provided by Intuit Payments Inc.
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: 10,
                                width: "100%"
                            }}>
                                <ReCAPTCHA
                                    sitekey="6Le1uZYeAAAAAETWoZ4IbNCLNZVbfcUPHUqYbWd1"
                                    onChange={(value: any) => {
                                        setRecaptchaToken(value)
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Button
                                id="submitCountButton"
                                type="submit"
                                variant="contained"
                                color="success"
                                disabled={props.paymentDescription === "No payment indicated."}
                                style={{marginTop: 10, height: 45, width: "100%"}}
                                onClick={() => props.submitPayment(recaptchaToken, notes)}
                            >
                                Submit Payment!
                            </Button>
                        </div>
                    </div>
                </CardContent>
            </PaddedCard>
        );
    }

    if(props.loading) {
        return loadingLayout();
    } else {
        return isDesktop ? renderDesktop() : renderMobile();
    }
}

export default PaymentSummaryCard;